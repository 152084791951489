@tailwind base;
@tailwind components;
@tailwind utilities;

/*@layer componenets {*/
/*    Typography Link {*/
/*        text-weight: bold;*/
/*    }*/

/*    !* ... *!*/
/*}*/